
// SMT参数格式化
export const formatPcbaReturnParams = (smtParams, pcbParams, otherParams = {}) => {
  const params = { ...smtParams, ...otherParams }
  const { order_info, address, invoice, ...smt_params } = params

  // 删除前端字段
  delete smt_params.pcbParams
  delete smt_params.saveLoading
 
  // 将 order_info, address, invoice 提取到与 smt_params 同级
  return {
    order_info,
    address,
    invoice,
    smt_params,
    pcb_params: smt_params.custom_pcb_ban == 1 ? { ...pcbParams, express: smt_params.express, fhp: 0 } : {}
  }
}

// PCBA返单页下单校验
export const validatePcbaReturnParams = params => {
  const valids = [
    { 
      key: 'buy_bom',
      flag: !params.buy_bom, 
      tips: '请选择是否华秋采购BOM'
    },
    { 
      key: 'custom_pcb_ban',
      flag: params.order_info.return_pcb_order_sn && params.order_info.return_pcb_order_sn !== '0' && !params.custom_pcb_ban, 
      tips: '请选择是否华秋定制PCB返单'
    },
    { 
      key: 'bcount',
      flag: params.custom_pcb_ban == 1 && !params.pcb_bcount, 
      tips: '请选择PCB数量'
    },
    { 
      key: 'number',
      flag: !params.number, 
      tips: '请输入生产数量'
    },
    { 
      key: 'solder_paste_type',
      flag: !params.solder_paste_type, 
      tips: '请选择制程要求'
    },
    { 
      key: 'is_first_confirm',
      flag: !params.is_first_confirm && params.is_first_confirm !== 0, 
      tips: '请选择样品图确认'
    },
  ]

  return valids.filter(item => {
    const flag = typeof item.flag === 'function' ? item.flag() : item.flag
    return flag
  })
}
